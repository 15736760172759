import React, { useEffect } from 'react';
import styled from 'styled-components';
import propsToDom from 'core/helpers/propsToDom';
import { asCol, rem } from 'styles/utils';
import Link from './Link';
import { Container } from './Container';
import { usePageContext } from 'store/PageProvider';
import { SmallCSS } from 'styles/typography';
import { useGlobalContext } from 'store/GlobalProvider';
import useOffsetSize from 'hooks/useOffsetSize';
import { LinkUnderline } from './LinkUnderline';
import media from 'styles/media';

const HeaderComponent = ({ ...others }) => {
  const [$module, { height }] = useOffsetSize();
  const { setHeaderHeight } = useGlobalContext();
  const { siteData } = usePageContext();
  const menu = siteData.header_menu.filter(({ link, label }, index) => !!link?.url && !!label);

  useEffect(() => {
    setHeaderHeight(height);
  }, [setHeaderHeight, height]);

  return (
    <Container ref={$module} as="header" {...propsToDom(others)}>
      <Logo to="/">
        <img src="/images/logo.svg" width="181" height="102" alt="Nuit Sauvage logo" />
      </Logo>
      <Nav>
        {menu.map(({ link, label, anchor }, index) => {
          return (
            <LinkUnderline
              as={LinkUnderline}
              key={link.url + index}
              to={link.url}
              target={link.target}
              state={{ anchor }}
            >
              {label}
            </LinkUnderline>
          );
        })}
      </Nav>
    </Container>
  );
};

const Logo = styled(Link)`
  width: ${rem(140)};

  img {
    width: 100%;
    height: auto;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;

  ${LinkUnderline} {
    ${SmallCSS}
    text-transform: lowercase;

    &:not(:last-child) {
      margin-right: ${asCol(2)};
    }
  }
`;

export const Header = styled(HeaderComponent)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndex.header};
  padding-top: ${asCol(1.25)};
  padding-bottom: ${asCol(1.25)};
  display: flex;
  align-items: flex-start;
  pointer-events: none;

  a,
  button {
    pointer-events: auto;
  }

  ${media.mobile`
    padding-top: ${asCol(3)};
    padding-bottom: ${asCol(3)};
  `}
`;
