import React, { createContext, useState, useContext, useEffect } from 'react';
import { useMedia, useWindowSize } from 'react-use';
import { mqMobile, mqPortrait } from 'styles/media';

export const GlobalContext = createContext({
  savedScrollTop: 0,
  scrollEnabled: true,
  headerHeight: null,
});

const GlobalProvider = ({ children }) => {
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [savedScrollTop, setSavedScrollTop] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(null);
  const [isClient, setClient] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const isMobile = useMedia(mqMobile);
  const isPortrait = useMedia(mqPortrait);
  const { width: ww, height: wh } = useWindowSize(null, null);

  useEffect(() => {
    setClient(true);
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        isMobile,
        isPortrait,
        isMenuOpen,
        setMenuOpen,
        ww,
        wh,
        isClient,
        headerHeight,
        setHeaderHeight,
        scrollEnabled,
        setScrollEnabled,
        savedScrollTop,
        setSavedScrollTop,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw Error('useGlobalContext must be used inside a `GlobalProvider`');
  }
  return context;
};
