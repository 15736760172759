import styled from 'styled-components';
import media from 'styles/media';
import { asColPercent } from 'styles/utils';

export const Container = styled.div`
  padding-left: ${asColPercent(2)};
  padding-right: ${asColPercent(2)};
`;

export const ContainerGrid = styled(Container)`
  display: grid;
  grid-template-columns: ${({ nbCols = 36 }) => `repeat(${nbCols}, minmax(1px, 1fr)) `};
  grid-column-gap: ${({ theme }) => theme.spacing.gridColumnGap};

  ${media.mobile`
    grid-template-columns: minmax(0, 1fr);

    & > * {
      grid-column: 1 / -1 !important;
    }
  `}
`;
