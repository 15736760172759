import React from 'react';
import styled from 'styled-components';
import PageProvider from 'store/PageProvider';
import { useGlobalContext } from 'store/GlobalProvider';
import GlobalStyle from './GlobalStyle';
import Helmet from 'react-helmet';
import theme from 'styles/theme';
import { Footer } from './Footer';
import { Header } from './Header';
import { useUpdateEffect } from 'react-use';
import useScollEnabled from 'hooks/useScrollEnabled';
import { NewsletterForm } from './NewsletterForm';

const Layout = ({ children, location, pageContext }) => {
  const resetScroll = useScollEnabled();
  const { pageType } = pageContext;
  const isPage404 = pageType === 'page_404';
  const { scrollEnabled, headerHeight, isMenuOpen, setMenuOpen, wh } = useGlobalContext();

  useUpdateEffect(() => {
    if (isMenuOpen) {
      resetScroll();
      setMenuOpen(false);
    }
  }, [children]);

  return (
    <PageProvider pageContext={pageContext}>
      <Helmet>
        {/* eslint-disable */}
        <link rel="preconnect" href="https://eminente.cdn.prismic.io" />
        <link rel="apple-touch-icon" sizes="180x180" href="/images/favicons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/images/favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/images/favicons/favicon-16x16.png" />
        <link rel="manifest" href="/images/favicons/site.webmanifest" />
        <link rel="mask-icon" href="/images/favicons/safari-pinned-tab.svg" color={theme.colors.metaThemeColor} />
        <link rel="shortcut icon" href="/images/favicons/favicon.ico" />
        <meta name="msapplication-config" content="/images/favicons/browserconfig.xml" />
        <meta name="theme-color" content={theme.colors.metaThemeColor} />
        {/* eslint-enable */}
      </Helmet>
      <GlobalStyle scrollEnabled={scrollEnabled} viewportHeight={wh} headerHeight={headerHeight} />
      <Header />
      <Main>{children}</Main>
      {!isPage404 && (
        <>
          <NewsletterForm />
          <Footer />
        </>
      )}
    </PageProvider>
  );
};

const Main = styled.main``;

export default Layout;
