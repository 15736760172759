import React from 'react';
import { usePageContext } from 'store/PageProvider';
import styled from 'styled-components';
import { asCol, rem } from 'styles/utils';
import propsToLink from 'core/helpers/propsToLink';
import { ContainerGrid } from './Container';
import Link from './Link';
import Icon from './Icon';
import { Small, SmallCaps } from 'styles/typography';
import { rgba } from 'polished';
import media from 'styles/media';

const FooterComponent = ({ ...others }) => {
  const { siteData } = usePageContext();
  const { contacts, instagram_url, facebook_url } = siteData;
  const hasInstagram = !!instagram_url?.url;
  const hasFacebook = !!facebook_url?.url;

  return (
    <ContainerGrid {...others}>
      <div>
        <img src="/images/logo.svg" width="181" height="102" alt="Nuit Sauvage logo" />
      </div>
      {contacts.map(({ name, email, instagram_username, instagram_url1 }, index) => {
        const hasLink = !!instagram_url1?.url && !!instagram_username;
        return (
          <Contact key={name + index}>
            {name && <SmallCaps>{name}</SmallCaps>}
            {email && (
              <Small>
                <Link to={`mailto:${{ email }}`}>{email}</Link>
              </Small>
            )}
            {hasLink && (
              <Small>
                <Link {...propsToLink(instagram_url1)}>{instagram_username}</Link>
              </Small>
            )}
          </Contact>
        );
      })}
      <Socials>
        {hasFacebook && (
          <Link to={facebook_url.url} aria-label="Facebook" target="_blank">
            <Icon id="facebook" />
          </Link>
        )}
        {hasInstagram && (
          <Link to={instagram_url.url} aria-label="Instagram" target="_blank">
            <Icon id="instagram" />
          </Link>
        )}
      </Socials>
    </ContainerGrid>
  );
};

const Socials = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  align-self: start;
  grid-column: 28 / -1;

  ${Link} {
    display: block;
    flex-shrink: 0;
    padding: ${asCol(0.25)};
  }

  ${Icon} {
    font-size: ${rem(18)};
  }

  ${media.mobile`
    justify-content: flex-start;
    margin-left: ${asCol(3)};

    ${Link} {
      padding: ${asCol(1)};
    }

    ${Icon} {
      font-size: ${rem(22)};
    }
  `}
`;

const Contact = styled.div`
  ${SmallCaps} {
    margin-bottom: ${rem(10)};
    color: ${({ theme }) => theme.colors.grey};
  }

  ${Small} {
    &:not(:last-child) {
      margin-bottom: ${rem(10)};
    }
  }

  ${media.mobile`
    text-align: left;
    padding-left: ${asCol(4)};
    padding-right: ${asCol(4)};
  `}
`;

export const Footer = styled(FooterComponent)`
  position: relative;
  padding-top: ${asCol(2)};
  padding-bottom: ${asCol(2.5)};
  background: url('/images/background.jpg') ${({ theme }) => theme.colors.black};
  background-size: cover;
  background-position: center 60%;

  &:before {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background: ${({ theme }) => rgba(theme.colors.black, 0.3)};
    z-index: 0;
  }

  div {
    position: relative;
    z-index: 3;

    &:first-child {
      grid-column: auto / span 6;
    }

    &:nth-child(2) {
      grid-column: 10 / span 7;
    }

    &:nth-child(3) {
      grid-column: 19 / span 7;
    }
  }

  ${media.mobile`
    text-align: center;
    padding-top: ${asCol(4)};
    padding-bottom: ${asCol(4)};
    grid-gap: ${asCol(2)};

    &:before {
      background: ${({ theme }) => rgba(theme.colors.black, 0.6)};
    }
  `}
`;
