import { css } from 'styled-components';

export const linkReset = css`
  text-decoration: none;
  outline: none;
  color: currentColor;
  cursor: pointer;
  letter-spacing: inherit;
`;

export const buttonReset = css`
  text-decoration: none;
  letter-spacing: inherit;
  font: inherit;
  border-radius: 0;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  cursor: pointer;
  outline: none;
  color: inherit;
  text-align: inherit;
  text-transform: inherit;
`;

export const hiddenScrollbarCSS = css`
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const bgPaper = css`
  background: url('/images/bg-paper.jpg') repeat ${({ theme }) => theme.colors.dust};
  background-size: 1024px;
`;
