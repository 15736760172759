import React from 'react';
import styled from 'styled-components';
import Link from './Link';
import Icon from './Icon';
import { asCol, rem } from 'styles/utils';
import { fontTitle } from 'styles/typography';
import media from 'styles/media';

const LinkFatComponent = ({ children, iconLeft = null, iconRight = null, ...others }) => {
  return (
    <Link {...others} data-icon={iconLeft !== null ? 'left' : iconRight !== null ? 'right' : null}>
      {iconLeft}
      <span>{children}</span>
      {iconRight}
    </Link>
  );
};

export const LinkFat = styled(LinkFatComponent)`
  ${fontTitle}
  position: relative;
  display: flex;
  align-items: center;
  padding: ${rem(15)} ${asCol(1)};
  background: ${({ theme }) => theme.colors.blue};
  text-align: center;
  justify-content: center;
  text-transform: uppercase;

  ${Icon} {
    position: relative;
    z-index: 3;
    flex-shrink: 0;
    margin-right: ${rem(10)};
    transition: transform 0.5s ${({ theme }) => theme.easings.expoOut};

    &[data-id='arrow-left'],
    &[data-id='arrow-right'] {
      font-size: ${rem(12)};
    }
  }

  span {
    position: relative;
    z-index: 3;

    & + ${Icon} {
      margin-left: ${rem(10)};
      margin-right: 0;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scaleX(0);
    transform-origin: 0% 100%;
    transition: transform 1s ${({ theme }) => theme.easings.expoOut};
    background: ${({ theme }) => theme.colors.purple};
    will-change: transform;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      &:before {
        transform: scaleX(1);
      }

      [data-id='arrow-left'] {
        transform: translateX(-10px);
      }

      [data-id='arrow-right'] {
        transform: translateX(10px);
      }
    }
  }

  ${media.mobile`
    flex-direction: column;

    ${Icon} {
      margin-right: 0;
      margin-bottom: ${rem(10)};
      order: 0;
    }

    span {
      order: 1;
    }
  `}
`;
