import propsToDom from 'core/helpers/propsToDom';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { asCol, rem } from 'styles/utils';
import { LinkFat } from 'components/LinkFat';
import { Title3 } from 'styles/typography';
import media from 'styles/media';

const NewsletterFormComponent = (props) => {
  const $form = useRef(null);
  const $email = useRef(null);
  const $opt_in = useRef(null);
  const [success, setSuccess] = useState(false);
  const [fields, setFields] = useState({ email: '', opt_in: false });
  const [errors, setErrors] = useState({ global: false, email: false, opt_in: false });

  const handleSubmit = (event) => {
    const send = async (data) => {
      const headers = {};
      headers['Accept'] = 'application/json';
      headers['Content-Type'] = 'application/json';
      headers['api-key'] =
        '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';

      try {
        const response = await fetch('https://api.sendinblue.com/v3/contacts', {
          headers,
          method: 'POST',
          body: JSON.stringify({ email: data.email, attributes: { OPT_IN: true }, listIds: [11] }),
        });

        const json = await response.json();

        if (response.status === 400) {
          setErrors((prev) => ({ ...prev, global: json.message }));
        } else {
          setSuccess(true);
        }
      } catch (e) {
        setErrors((prev) => ({ ...prev, global: 'An error occured, please retry later.' }));
      }
    };

    event.preventDefault();

    if (!$form.current.checkValidity()) {
      const nextErrors = { ...errors };
      if (!$email.current.checkValidity()) nextErrors.email = true;
      if (!$opt_in.current.checkValidity()) nextErrors.opt_in = true;
      return setErrors(nextErrors);
    }

    send(fields);
    setFields({ email: '', opt_in: false });
  };

  useEffect(() => {
    setSuccess(false);
    setErrors({ global: false, email: false, opt_in: false });
  }, [fields]);

  return (
    <div {...propsToDom(props)}>
      <form noValidate ref={$form} onSubmit={handleSubmit}>
        <Container>
          <Left>
            <Title3>Subscribe to our newsletter</Title3>

            <FieldCheckbox>
              <Flex>
                <Checkbox
                  ref={$opt_in}
                  id="opt_in"
                  type="checkbox"
                  name="opt_in"
                  checked={fields.opt_in}
                  onChange={(e) => setFields((prev) => ({ ...prev, opt_in: !fields.opt_in }))}
                  required
                />
                <Label for="opt_in">I agree to receive your emails</Label>
              </Flex>
              {errors.opt_in && <Error>This field is mandatory.</Error>}
            </FieldCheckbox>
          </Left>
          <Right style={{ flexGrow: 3 }}>
            <Flex>
              <Field>
                <Input
                  ref={$email}
                  type="email"
                  placeholder="mail@example.com"
                  onChange={(e) => setFields((prev) => ({ ...prev, email: e.target.value }))}
                  name="email"
                  value={fields.email}
                  required
                />
              </Field>
              <LinkFat type="submit">Subscribe</LinkFat>
            </Flex>
            {errors.email && (
              <Error>This field is mandatory &amp; should be in an email format.</Error>
            )}
          </Right>
        </Container>
        {errors.global && <Error>{errors.global}</Error>}
        {success && <Success>Your registration is confirmed!</Success>}
      </form>
    </div>
  );
};

const Left = styled.div`
  margin-right: ${({ theme }) => theme.spacing.s10};

  ${media.mobile`
    margin-right: 0;
    margin-bottom: ${({ theme }) => theme.spacing.s1};
  `}
`;

const Right = styled.div`
  flex-grow: 3;

  ${media.mobile`
    width: 100%;
  `}
`;

const Field = styled.div`
  flex-grow: 3;
`;

const Input = styled.input`
  box-sizing: border-box;
  appearance: none;
  border: 0;
  margin: 0;
  padding: ${rem(15)} ${asCol(1)};
  font: inherit;
  background: ${({ theme }) => theme.colors.whitesmoke};
  color: ${({ theme }) => theme.colors.black};
  width: 100%;
  outline: 0;

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey};
  }
`;

const FieldCheckbox = styled(Field)`
  margin-top: ${({ theme }) => theme.spacing.s1};
`;

const Checkbox = styled.input`
  margin-right: ${({ theme }) => theme.spacing.s2};
`;

const Label = styled.label`
  font-size: ${rem(14)};
`;

const Error = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: ${rem(14)};
  margin-top: ${({ theme }) => theme.spacing.s1};
`;

const Success = styled.p`
  color: ${({ theme }) => theme.colors.purple};
  margin-top: ${({ theme }) => theme.spacing.s1};
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;

  ${media.mobile`
    flex-direction: column;
  `}
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const NewsletterForm = styled(NewsletterFormComponent)`
  padding: ${asCol(1.25)} ${asCol(2)};

  ${media.mobile`
    padding-top: ${asCol(2)};
    padding-bottom: ${asCol(2)};
  `}
`;
