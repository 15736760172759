// import { rem, rgba } from 'polished';
import { rem } from 'polished';
import easings from './easings';

const defaultGridSize = 40;
const designedDesktop = 1440;
const designedMobile = 400;
const baseFontSize = 18;
const baseLineHeight = 36 / 18;
const mobileLineHeight = 27 / 16;

const theme = {
  defaultGridSize,
  designedDesktop,
  designedMobile,
  zIndex: {
    header: 10,
  },
  colors: {
    metaThemeColor: '#000000',
    black: '#000000',
    white: '#ffffff',
    whitesmoke: '#E7E7E7',
    blue: '#333472',
    grey: '#808080',
    purple: '#7A396D',
    error: '#d40a0a',
  },
  fontWeight: {
    normal: 'normal',
    medium: 500,
  },
  fontSize: {
    base: baseFontSize,
  },
  lineHeight: {
    base: baseLineHeight,
    mobile: mobileLineHeight,
  },
  spacing: {
    s1: rem(10, baseFontSize),
    s1_5: rem(15, baseFontSize),
    s2: rem(20, baseFontSize),
    s3: rem(30, baseFontSize),
    s4: rem(40, baseFontSize),
    s5: rem(50, baseFontSize),
    s6: rem(60, baseFontSize),
    s7: rem(70, baseFontSize),
    s8: rem(80, baseFontSize),
    s9: rem(90, baseFontSize),
    s10: rem(100, baseFontSize),
    s16: rem(160, baseFontSize),
  },
  easings,
};

export default theme;
