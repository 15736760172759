import styled, { css } from 'styled-components';
import { rem } from './utils';
import media from 'styles/media';

export const fontTitle = css`
  font-family: 'dela_gothic_oneregular', serif;
`;

// ------------------------------------------------------------
//    TITLES
// ------------------------------------------------------------

export const h1 = css`
  ${fontTitle}
  font-size: ${rem(54)};
  line-height: ${63 / 54};

  ${media.mobile`
    font-size: ${rem(43)};
  `}
`;

export const Title1 = styled.h1`
  ${h1}
`;

export const h2 = css`
  ${fontTitle}
  font-size: ${rem(20)};
  text-transform: uppercase;
`;

export const Title2 = styled.h2`
  ${h2}
`;

export const h3 = css`
  ${fontTitle}
`;

export const Title3 = styled.h3`
  ${h3}
  text-transform: uppercase;
`;

export const h4 = css``;

export const Title4 = styled.h4``;

export const h5 = css``;

export const Title5 = styled.h5``;

export const text = css`
  font-size: ${rem(18)};
`;

export const CapsCSS = css``;

export const Caps = styled.p``;

export const SmallCapsCSS = css`
  font-size: ${rem(14)};
  line-height: ${20 / 14};
  font-weight: bold;
  text-transform: uppercase;
`;

export const SmallCaps = styled.p`
  ${SmallCapsCSS};
`;

export const SmallCSS = css`
  font-size: ${rem(14)};
  line-height: ${20 / 14};
`;

export const Small = styled.p`
  ${SmallCSS};
`;
