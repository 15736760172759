import React, { forwardRef } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import propsToDom from 'core/helpers/propsToDom';
import { buttonReset } from 'styles/others';
import styled from 'styled-components';

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
const Link = (
  { children, to, state, activeClassName = 'is-active', isBroken = false, ...others },
  ref
) => {
  if (!to || isBroken) {
    return <button {...propsToDom(others)}>{children}</button>;
  }

  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        ref={ref}
        to={to}
        state={state}
        activeClassName={activeClassName}
        onClick={others.onClick}
        {...propsToDom(others)}
      >
        {children}
      </GatsbyLink>
    );
  }

  return (
    <a
      ref={ref}
      href={to}
      {...propsToDom(others)}
      rel={others.target === '_blank' ? 'noopener' : null}
    >
      {children}
    </a>
  );
};

export default styled(forwardRef(Link))`
  ${buttonReset}
`;
