import { createGlobalStyle } from 'styled-components';
import { rem } from 'styles/utils';
import media from 'styles/media';

const GlobalStyle = createGlobalStyle`
  :root {
    --viewport-height: ${({ viewportHeight }) =>
      viewportHeight ? `${viewportHeight}px` : '100vh'};
    --vh: ${({ viewportHeight }) => (viewportHeight ? `${viewportHeight / 100}px` : '1vh')};
    --header-height: ${({ headerHeight }) => (headerHeight ? `${headerHeight}px` : null)};
  }

  html {
    font-size: max(${rem(9)}, ${(18 / 1440) * 100}vw);
    line-height: ${({ theme }) => theme.lineHeight.base};
    background: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.font};
    -webkit-font-smoothing: antialiased;
    overscroll-behavior: none;

    ${media.mobile`
      font-size: ${rem(16)};
      line-height: ${({ theme }) => theme.lineHeight.mobile};
    `}
  }

  body {
    position: ${({ scrollEnabled }) => (scrollEnabled ? null : 'fixed')};
    overflow-y: ${({ scrollEnabled }) => (scrollEnabled ? null : 'scroll')};
    width: 100%;
    font-size: ${rem(18)};
    overscroll-behavior: none;

    ${media.mobile`
      font-size: ${rem(16)}
    `}
  }

  .scrollable {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .mobile-only {
    ${media.fromMobile`
      display: none !important;
    `}
  }

  .no-mobile {
    ${media.mobile`
      display: none !important;
    `}
  }
`;

export default GlobalStyle;
