import { useIsomorphicLayoutEffect } from 'react-use';

import { useRef, useState, useMemo } from 'react';

const defaultState = {
  width: 0,
  height: 0,
};

const useOffsetSize = () => {
  const ref = useRef(null);
  const [rect, setRect] = useState(defaultState);

  const observer = useMemo(() => {
    if (typeof window !== 'undefined')
      return new ResizeObserver((entries) => {
        if (entries[0]) {
          setRect({ width: entries[0].target.offsetWidth, height: entries[0].target.offsetHeight });
        }
      });
  }, []);

  useIsomorphicLayoutEffect(() => {
    const element = ref.current;
    if (!element) return;
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [ref.current]);

  return [ref, rect];
};

export default useOffsetSize;
