import styled from 'styled-components';
import Link from './Link';

export const LinkUnderline = styled(Link)`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    transform: scaleX(0);
    transform-origin: 0% 50%;
    background: currentColor;
    transition: transform 0.4s ${({ theme }) => theme.easings.principle};
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover:before {
      transform: scaleX(1);
    }
  }
`;
