import isPropValid from '@emotion/is-prop-valid';

function propsToDom(object) {
  return Object.keys(object).reduce((acc, key) => {
    if (isPropValid(key)) {
      acc[key] = object[key];
    }
    return acc;
  }, {});
}

export default propsToDom;
