import React from 'react';
import styled from 'styled-components';
import Facebook from '-!svg-react-loader!../assets/svgs/facebook.svg';
import Instagram from '-!svg-react-loader!../assets/svgs/instagram.svg';
import Soundcloud from '-!svg-react-loader!../assets/svgs/soundcloud.svg';
import Spotify from '-!svg-react-loader!../assets/svgs/spotify.svg';
import Beatport from '-!svg-react-loader!../assets/svgs/beatport.svg';
import Bandcamp from '-!svg-react-loader!../assets/svgs/bandcamp.svg';
import Discogs from '-!svg-react-loader!../assets/svgs/discogs.svg';
import ResidentAdvisor from '-!svg-react-loader!../assets/svgs/resident-advisor.svg';
import CircleArrowRight from '-!svg-react-loader!../assets/svgs/circle-arrow-right.svg';
import ArrowLeft from '-!svg-react-loader!../assets/svgs/arrow-left.svg';
import ArrowRight from '-!svg-react-loader!../assets/svgs/arrow-right.svg';
import Play from '-!svg-react-loader!../assets/svgs/play.svg';

const Icon = ({ id, ...others }) => {
  switch (id) {
    case 'facebook':
      return <Facebook data-id={id} {...others} />;
    case 'instagram':
      return <Instagram data-id={id} {...others} />;
    case 'soundcloud':
      return <Soundcloud data-id={id} {...others} />;
    case 'resident_advisor':
    case 'resident-advisor':
      return <ResidentAdvisor data-id={id} {...others} />;
    case 'circle-arrow-right':
      return <CircleArrowRight data-id={id} {...others} />;
    case 'arrow-left':
      return <ArrowLeft data-id={id} {...others} />;
    case 'arrow-right':
      return <ArrowRight data-id={id} {...others} />;
    case 'play':
      return <Play data-id={id} {...others} />;
    case 'spotify':
      return <Spotify data-id={id} {...others} />;
    case 'beatport':
      return <Beatport data-id={id} {...others} />;
    case 'bandcamp':
      return <Bandcamp data-id={id} {...others} />;
    case 'discogs':
      return <Discogs data-id={id} {...others} />;
    default:
      return null;
  }
};

export default styled(Icon)`
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  vertical-align: top;
  will-change: transform;

  &[data-id='resident-advisor'],
  &[data-id='resident_advisor'] {
    width: ${36 / 15}em;
  }

  &[data-id='soundcloud'] {
    width: ${30 / 15}em;
  }

  &[data-id='beatport'] {
    width: ${14 / 17}em;
  }

  &[data-id='bandcamp'] {
    width: ${36.65 / 12}em;
  }

  &[data-id='discogs'] {
    width: ${41.38 / 15}em;
  }

  &[data-id='arrow-left'],
  &[data-id='arrow-right'] {
    width: ${37 / 12}em;
  }
`;
