import 'styles/global.css';

import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from 'styles/theme';
import GlobalProvider from 'store/GlobalProvider';
import Layout from 'components/Layout';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <PrismicPreviewProvider>
        <GlobalProvider>{element}</GlobalProvider>
      </PrismicPreviewProvider>
    </ThemeProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  if (props.pageContext.fromPrismic) {
    return <Layout {...props}>{element}</Layout>;
  } else {
    return element;
  }
};
